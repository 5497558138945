var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('h3',{staticClass:"mb-0"},[_c('span',{staticClass:"text-before-button"},[_vm._v(_vm._s(_vm.subscription.code))]),(_vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SUBSCRIPTIONS))?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.editSubscription()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SUBSCRIPTIONS) &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_CREATE_INVOICES)
                )?_c('base-button',{attrs:{"type":"info","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.renewSubscription()}}},[_c('span',{staticClass:"btn-inner--icon"},[_vm._v(" "+_vm._s(_vm.$t("SUBSCRIPTIONS.RENEW"))+" "),_c('i',{staticClass:"fas fa-plus-circle"})])]):_vm._e(),(
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SUBSCRIPTIONS) &&
                  false
                )?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.deleteSubscription()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)]),_c('div',{staticClass:"col-4 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")]),_c('notification-subscription',{attrs:{"objectType":'subscriptions',"objectId":_vm.subscription.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}})],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('subscription-view-global',{attrs:{"subscription":_vm.subscription}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('subscription-view-logs',{attrs:{"subscription":_vm.subscription}})],1):_vm._e()],1)],1)],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }